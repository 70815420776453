import React, { useState } from "react";
import { Card, Col, Container, Image, Row } from 'react-bootstrap';
import Constant from "../../config/Constant";

import Slider from "react-slick";
import "../../../node_modules/slick-carousel/slick/slick.scss"
import "../../../node_modules/slick-carousel/slick/slick-theme.scss"

const Blogs = ({data = []}) => {

    const [blogData, setBlogData] = useState([])
    
    return (
        <div className={`bg-white py-4 blog-section ${blogData?.length <= 0 && 'd-none'}`}>
            <Container>
                <Row>
                    <Col lg='12'>
                        <h2 className="text-start ff-playfair fw-bold">New Updates and Blogs</h2>
                        <h6 className="text-start ff-manrope fw-400 text-grey-500">Top Astrologers. 24 * 7 customer support. Happy to help.</h6>
                    </Col>
                </Row>
                <Row className="mt-3">
                    <Col lg='12'>
                        <Slider
                            autoplay={true}
                            responsive={[
                                {
                                    breakpoint:1024,
                                    settings:{
                                        slidesToShow:3,
                                        slidesToScroll:1,
                                        infinite:true
                                    }
                                },
                                {
                                    breakpoint:784,
                                    settings:{
                                        slidesToShow:2,
                                        slidesToScroll:1,
                                        infinite:true
                                    }
                                },
                                {
                                    breakpoint:600,
                                    settings:{
                                        slidesToShow:1,
                                        slidesToScroll:1,
                                        infinite:true
                                    }
                                }
                            ]}
                            infinite={true}
                            speed={1000}
                            slidesToShow={3}
                            slidesToScroll={1}
                        >
                        {
                            data?.length > 0 && data?.map((row) => {
                                return (
                                    <Card className="bg-transparent p-2 border-0">
                                        <Card.Body className="border-1">
                                            <div className="h-60 blog-image p-1" style={{backgroundImage:`url(${row?.imageFullUrl})`}}>
                                                <Row className="align-items-center">
                                                    <Col lg='6'>
                                                        <div className="px-4">
                                                            <h3 className="ff-manrope fw-bold text-warning">{row?.name}</h3>
                                                            <label className="fs-12 text-white ff-manrope">{row?.description}</label>
                                                        </div>
                                                    </Col>
                                                </Row>
                                            </div>
                                            <div className="h-40 d-flex justify-content-center">
                                                <div className="p-2 pt-0">
                                                    <div className="blog-info d-flex flex-column">
                                                        <div className="h-30">
                                                            <label className="fs-6 ff-manrope text-black text-start"><b>{row?.title}: {row?.description}</b></label>
                                                        </div>
                                                        <div className="d-flex justify-content-between pt-1">
                                                            <label className="text-muted fs-13 ff-manrope">{row?.name}</label>
                                                            <label className="text-muted fs-13 ff-manrope">{Constant?.moment(row?.created_at)?.format('D, M Y')}</label>
                                                        </div>
                                                        <div className="d-flex justify-content-between mt-1">
                                                            <button className="w-100 p-2 fs-14 fw-bold text-capitalize ff-manrope">
                                                                read more
                                                            </button>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </Card.Body>
                                    </Card>
                                )
                            })
                        }
                        </Slider>
                    </Col>
                    <Col lg='12'>
                        <div className="p-4 text-center">
                            <button className="see-all px-4 py-1 fs-5 text-capitalize">
                                see all
                            </button>
                        </div>
                    </Col>
                </Row>
            </Container>
        </div>
    )
}

export default Blogs